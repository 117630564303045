import React, { useState } from 'react';
import axios from 'axios';

const ProgramCard = ({ title, directory }) => {
    const [output, setOutput] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const executeProgram = async () => {
        try {
            const formData = new FormData();
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            // Update the URL to point to your Laravel API
            const response = await axios.post(
                `https://api.jeremiah.business/python-portal/run/${encodeURIComponent(directory)}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setOutput(response.data.output);
        } catch (error) {
            setOutput(`Error: ${error.response ? error.response.data.error : error.message}`);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <div className="program-card">
            <h2>{title}</h2>
            {title === "PDF Processor" && (
                <div>
                    <input type="file" onChange={handleFileChange} />
                </div>
            )}
            <button onClick={executeProgram}>Run Code</button>
            <div className="output fade-in">{output}</div>
        </div>
    );
};

export default ProgramCard;

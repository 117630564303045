import React, { useEffect, useState } from 'react';
import './App.css';
import ProgramCard from './components/ProgramCard';
import axios from 'axios';

const App = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get('https://api.jeremiah.business/python-portal/programs');
        console.log(response.data);




        // Define the list of programs to exclude (by name or directory)
        const excludedPrograms = ['Venv',]; // Replace these with actual program names you want to exclude

        const fetchedPrograms = response.data.programs
          .map(program => ({
            name: program.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            directory: program
          }))
          .filter(program => !excludedPrograms.includes(program.name)); // Filter out the excluded programs

        setPrograms(fetchedPrograms);
      } catch (error) {
        console.error('Failed to fetch programs:', error);
      }
    };

    fetchPrograms();
  }, []);

  return (
    <div className="container">
      <div className="header">
        <img src="logo.png" alt="Favicon" className="favicon" />
        <h1>Python Portal</h1>
      </div>

      <div className="program-grid">
        {programs.map((program) => (
          <ProgramCard
            key={program.directory}
            title={program.name}
            directory={program.directory}
          />
        ))}
      </div>
    </div>
  );
};

export default App;
